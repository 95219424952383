/* --------------------------
Gナビ
-------------------------- */

.p-gnav{
  @include view-at(tab) {
    display: none;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.9);
    padding-top: 69px;

    &.visible {
      display: block;
    }
  }
  &-list{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: -15px;

    @include view-at(tab){
      flex-direction: column;
      margin: 0;
      padding: 0 20px;
    }

    &-item{
      padding: 15px;
      width:100%;
      text-align: center;

      @include view-at(tab){
        width:100%;
        padding: 0;
      }

      &.current{
        a{
          opacity: 1;
          pointer-events: none;

          @include view-at(sp){
            border: 1px solid $color-gray;
            color: $color-white;
          }

          &::after{
            content:'';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 4px;
            background: $color-red;
            opacity: 1;
            visibility: visible;

            @include view-at(sp){
              background: none;
            }
          }
        }
      }


      &--en{
        font-family: 'Marcellus', serif;
        font-size: 1.4em;
        font-weight: $bold;
        line-height: 1.6;
        letter-spacing: 0.2rem;
        text-indent:0.2em;

        @include view-at(sp){
          display: none;
        }
      }

      a{
        display: block;
        font-size: .8em;
        text-decoration: none;
        font-weight: $bold;
        letter-spacing: .1em;
        line-height: 1.2;
        padding: 10px;
        position: relative;
        

        @include view-at(tab){
          font-size: 1.2em;
          color: $color-white;
          padding: 15px 20px;
          margin: 10px 0;
        }



        &::after{
          content:'';
          position: absolute;
          bottom: 2px;
          left: 0;
          width: 100%;
          height: 4px;
          background: $color-red;
          opacity: 0;
          visibility: hidden;
          transition: .3s;

          @include view-at(sp){
            background: none;
          }
        }

        &:hover{
          &::after{
            bottom: -10px;
            opacity: 1;
            visibility: visible;

            @include view-at(sp){
              background: none;
            }
          }
        }
      }
    }
  }
}