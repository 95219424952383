/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-light-gray;

  &--border {
    border: 1px solid $color-gray;
    background-color: $color-white;
  }

  &--rounded {
    border-radius: 8px;
  }

  &-contents {
    padding: 30px;

    @include view-at(sp) {
      padding: 20px;
    }

    &--narrow {
      padding: 15px;
    }
  }
}
