/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  background: $color-gray;
  padding: 10px 0;

  &-list{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      &::after {
        content: '>';
        padding: 0 5px;
      }

      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
}
