/* --------------------------
ボタン
-------------------------- */

.c-button {
	display: inline-block;
	width: 250px;
	border: 1px solid $color-black;
	color:$color-black;
	font-size: 15px;
	letter-spacing: .08em;
	padding: 15px 40px;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	background-color: $color-white;
	position: relative;
	transition: all .3s ease-in-out;

	&::after{
		content: '';
		display: block;
		width:8px;
		height: 8px;
		border-top:1px solid $color-black;
		border-right:1px solid $color-black;
		transform: rotate(45deg);
		position: absolute;
		top:calc(50% - 5px);
		right:15px;
		transition: all .3s ease-in-out;
	}

	@include view-at(tab){
		width:auto;
	}

	&:hover{
		opacity: 1;
		background: $color-black;
		color:$color-white;

		&::after{
			border-color:$color-white;
			right:12px;
		}
	}


	&--primary {
		border-color:transparent;
		background: $color-red;
		color:$color-white;

		&::after{
			content: none;
		}

		&:hover{
			border-color:$color-red;
			background: $color-white;
			color:$color-red;

		}
	}

	&--white{
		border:none;
		color:$color-red;

		&::after{
			border-color:$color-red;
		}

		&:hover{
			background:$color-red;
		}
	}

	&--disabled {
		pointer-events: none;
		background-color: $color-gray;
		border-color: $color-gray;
		color: $color-white;

		&::after{
			content: none;
		}
	}


}
