.p-cta{
	background:$color-black;
	color:$color-white;
	padding: 80px 0;

	@include view-at(sp) {
		padding: 50px 0;
	}

	&-h1-heading{
		text-align: center;
		color:$color-white;
		margin-bottom:50px;

		@include view-at(sp){
			margin-bottom: 25px;
		}

		&--en{
			font-family: 'Marcellus', serif;
			font-size: 42px;
			line-height: 1.3;
			letter-spacing: 0.25rem;
			display: block;

			@include view-at(sp){
        		font-size: 31px;
      		}
		}

		&--ja{
			font-size: 18px;
			margin-top: 10px;
			line-height: 1.4;
			font-weight: $bold;
			display: block;

			@include view-at(sp){
        		font-size: 16px;
        		margin-top: 5px;
      		}
		}

	}

	&-button {
		color: #FFF;
		display: inline-block;
		padding: 30px 0;
		margin: 15px 0 0;
		text-align: center;
		font-weight: $bold;
		max-width: 500px;
		width:100%;
		position: relative;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid transparent;
		outline: 1px solid;
		outline-color: rgba(255, 255, 255, 0.5);
		outline-offset: 0px;
		text-shadow: none;
		transition: all 1.2s cubic-bezier(0.2, 1, 0.2, 1);

		@include view-at(sp) {
			padding: 20px 0;
		}

		&:hover {
			border-color: #FFF;
			box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
			outline-color: transparent;
			outline-offset: 12px;
			text-shadow: 2px 2px 3px #000;
		}
	}

}

