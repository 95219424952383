.contact{
  &-form{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    @include view-at(sp){
      width:100%;
      display: block;
    }

    &-title{
      width:35%;
      font-size: 18px;
      font-weight: $bold;
      padding: 40px 30px;
      line-height: 1.5;
      border-bottom:1px solid $color-gray;
      background: rgba(247, 247, 247,.5);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include view-at(sp){
        width:100%;
        padding: 15px;
        border: none;
      }
    }

    &-input{
      width:65%;
      padding: 40px 30px;
      border-bottom:1px solid $color-gray;

      @include view-at(sp){
        width:100%;
        padding: 20px 15px;
        border: none;
      }
    }

  }
}