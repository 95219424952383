.works{
  &-select{
    &-list{
      display: flex;
      justify-content: center;
      align-items: center;

      @include view-at(sp){
        display: none;
      }

      &-item{
        padding: 0 12px;
        letter-spacing: .1em;
        font-weight: $bold;

        a{
          display: block;
          text-decoration: none;

          &:hover{
            color:$color-red;
          }

        }
      }
    }
  }


  &-list{

    &-item{
      @include view-at(sp){
        padding: 20px 10px !important;
      }

      &-info{
        letter-spacing: .05em;
        text-align: center;
        margin-bottom: 1rem;

        @include view-at(sp){
          margin-bottom: 0.5rem;
        }
      }

      a{
        display: block;
        text-decoration: none;
        position: relative;

        &:hover{
          .works-list-item-info{
            color:$color-red;
          }
        }
      }
    }
    
    &-label{
      position: absolute;
      top: 0;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      margin: -3px;

      &-item{
        padding:3px;
      }
    }
  }

  &-thumbnail{
    &-image{
      overflow: hidden;
      width:100%;

      margin-bottom: 1rem;
      box-shadow: 0 0 10px .5px rgba(0,0,0,0.2);

      img{
        display: block;
        transition: all .3s;

        &:hover{
          transform: scale(1.05);
          transition-duration: .3s;
          opacity: 0.7;
        }
      }
    }
  }

  &-c-h1{
    background: $color-gray;
    padding: 50px;
    text-align: center;

    @include view-at(sp){
      padding: 25px 20px;
    }
  }

}