
body {
  font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  color: $color-black;
}

a {
  color: $color-black;

  &:hover {
    opacity: 0.6;
  }
}

p {
  text-align: justify;
  word-wrap: break-word;
  margin-bottom: 1em;
  font-size: nth($font-size, 5);
  line-height: 2;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 84px;

  @include view-at(tab) {
    padding-top: 59px;
  }

  @include view-at(sp) {
    padding-top: 45px;
  }
}

