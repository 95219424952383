.top{

  &-keyvisual{
    position: relative;
    height: calc(100vh - 85px);

    @include view-at(tab){
      height: 50vh;
    }

    @include view-at(sp){
      height: 120vh;
      min-height: auto;
    }

    &-image{
      width: 75%;
      height: 100%;
      background: url(../images/top/mv.jpg) no-repeat;
      background-size: cover;
      position: absolute;
      top:0;
      right:0;
      z-index: -1;

      @include view-at(tab){
        background: url(../images/top/mv.jpg) no-repeat;
        background-size: cover;
        width: 100%;

        img{
          display: none;
        }
      }

      @include view-at(sp){
        height: 100vh;
      }
    }

    &-bg{
      width: 100%;
      height: 30vh;
      background: $color-light-gray;
      position: absolute;
      bottom:0;
      right:0;
      z-index: -1;

      @include view-at(tab){
      }
    }

    &-container{
      width:90%;
      margin: 0 auto;
      padding:0 20px;

      @include view-at(tab){
        width:100%;
      }
    }

    &-wrapper{
      width:30%;
      position: absolute;
      top: 30%;
      transform: translateY(-30%);

      @include view-at(tab){
        width: 85%;
        top: 30%;
        transform: translateY(-90%);
      }

      @include view-at(sp){
        top: 40%;
        text-align: center;
        left: 50%;
        transform: translate(-50%,-50%);
      }

      p{
        @include view-at(sp){
          color: $color-white;
        }
      }
    }

    &-catchcopy{
      display: inline-block;
      font-size: 4rem;
      font-weight: $bold;
      letter-spacing: .01em;
      line-height: 1;
      background: $color-black;
      color:$color-white;
      padding: 14px 20px 20px;
      margin-bottom: .4em;

      @include view-at(tab){
        font-size: 2rem;
      }

      @include view-at(sp){
        padding: 15px 30px;
        margin-bottom: 30px;
      }

      &-sub{
        font-size:1.7rem;
        letter-spacing: .01em;
        line-height: 1;
        margin-bottom: .8em;

        @include view-at(tab){
          font-size: 1.2rem;
          text-align: center;
          color: $color-white;
        }
      }
    }
  }

  &-news{
    position: absolute;
    bottom: 0;
    right: 0;

    @include view-at(sp){
      right: 0;
      bottom: 20px;
    }

    dl{
      background: $color-white;
      display: flex;

      @include view-at(sp){
        padding: 20px;
        width: 90%;
        margin: 0 auto;
        display: block;
      }

      dt{
        font-size: 22px;
        letter-spacing: 0.1em;
        font-weight: bold;
        padding: 30px;
        background: $color-black;
        color: $color-white;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include view-at(sp){
          font-size: 18px;
          padding-bottom: 10px;
          padding: 0;
          padding-bottom: 10px;
          background: no-repeat;
          color: #333;
          margin-right: 0;
          border-right: none;
          border-bottom: 1px solid #e3e3e3;
          text-align: left;
          display: block;
        }
      }

      dd{
        display: flex;
        justify-content: center;
        align-items: center;

        @include view-at(sp){
          padding-top: 10px;
        }

        p{
          line-height: 1.5em;
          font-size: 14px;

          @include view-at(sp){
            font-size: 12px;
          }
        }
      }
    }
  }

  &-service{
    &-contents{
      text-align: center;
      padding: 30px 20px;

      @include view-at(tab){
        padding: 0;
      }
    }

    &-icon{
      width:75%;
      margin: 0 auto 30px;
      position: relative;

      @include view-at(sp){
        margin: 15px auto;
        max-width: 200px;
      }

      &::before{
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
        background: $color-light-gray;
        border-radius: 50%;
      }

      img{
        position: absolute;
        top:15%;
        left: 0;
        right:0;
        margin: 0 auto;
        width: 75%;

        &.consulting{
          top: 9%;
        }
      }
    }
  }

  &-works{
    &-list{
      overflow: hidden;

      &-item{
        max-width: 100%;
        padding: 0 10px;

        a{
          display: block;

        }

        img {
          transform: scale(0.8);
          transition: 0.5s;
        }

        &.slick-current{
          img{
            transform: scale(1);
            box-shadow: 0 0 10px .5px rgba(0,0,0,0.4);
          }
        }
      }
    }
  }
}
