/* --------------------------
Fナビ
-------------------------- */

.p-fnav{
	width: 100%;

	&-list{
		display: flex;
		justify-content: flex-end;
		margin: -20px;

		@include view-at(sp){
			flex-wrap: wrap;
			justify-content: unset;
			margin: 0;
			margin-bottom: 10px;
		}

		&-item{
			padding: 20px;
			text-align: center;

			@include view-at(sp){
				width: auto;
				padding: 5px 10px;
				text-align: left;
			}

			a{
				display: block;
				text-decoration: none;
				color:$color-gray;
				font-weight: $bold;
				letter-spacing: .1em;
				line-height: 1.2;

				@include view-at(sp){
					font-size: 14px;
				}

			}
		}
	}
}