/* --------------------------
フッター
-------------------------- */

.p-footer {
	background: $color-black;
	padding-bottom: 25px;

	@include view-at(sp){
		padding-bottom: 10px;
	}

	&-container{
		border-top:1px solid $color-light-gray;
		width:90%;
		margin: 0 auto;
		padding: 50px 0;

		@include view-at(tab){
			padding: 30px 0;
		}

		@include view-at(sp){
			padding: 50px 0;
			border-top:1px solid rgba(255, 255, 255, 0.5);
		}
	}

	&-contents{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		@include view-at(sp){
			flex-direction: column;
		}
	}

	&-logo{

		@include view-at(sp){
			margin-bottom: 20px;
			width: 100%;
		}

		a{
			display: block;

			@include view-at(sp){
				text-align: center;
			}

			img{
				width:65%;

				@include view-at(sp){
					width: 140px;
				}
			}
		}
	}

	&-copyright{
		color:$color-gray;
		text-align: center;

		@include view-at(sp){
			font-size: 12px;
		}
	}
}
