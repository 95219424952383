/* --------------------------
画像キャプション
-------------------------- */

.c-image {
  position: relative;
  &-caption {
    position: absolute;
    left: 20px;
    bottom:20px;
    color:$color-white;
    text-shadow: 0 0 2px rgba(0,0,0,0.4);
  }
}