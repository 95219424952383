/* --------------------------
見出し
-------------------------- */

.c-h1 {
  background-image: url(../images/common/hero.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &-heading{
    color:$color-black;
    text-align: center;
    padding: 50px 0;

    @include view-at(sp){
      
    }

    &--en{
      font-family: 'Marcellus', serif;
      font-size: 42px;
      line-height: 1.3;
      letter-spacing: 0.25rem;
      display: block;

      @include view-at(sp){
        font-size: 28px;
      }
    }

    &--ja{
      font-size: 18px;
      margin-top: 10px;
      line-height: 1.4;
      font-weight: $bold;
      display: block;

      @include view-at(sp){
        font-size: 16px;
        margin-top: 5px;
      }
    }

  }

}

.c-h2 {
  font-size:24px;
  font-weight: $bold;
  line-height: 1.3;
  letter-spacing: 0.15em;

  @include view-at(sp) {
    font-size:18px; 
  }

  &::after{
      content:'';
      display:block;
      width:100px;
      height:2px;
      background: $color-red;
      margin: 35px 0 20px;

      @include view-at(sp) {
        margin: 20px 0;
      }
    }

    &--small{
      font-family: 'Roboto', sans-serif;
      font-size:16px;
      font-weight: $bold;
      line-height: 1.3;
      letter-spacing: 0.15em;
      margin: 20px 0 40px;
    }

  }



.c-h3 {

  font-size: nth($font-size, 3);
  line-height: nth($line-height, 2);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 30px;


  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
    line-height: nth($line-height-sp, 2);
    margin-bottom: 15px;
  }
}

.c-h4 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 10px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.c-h5 {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}

.c-h6 {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}