/* --------------------------
リンク
-------------------------- */

.c-link {
  color: $color-black;

  &--block {
    display: block;
    text-decoration: none;
  }

  &--none {
    text-decoration: none;
  }
  &--disabled{
	pointer-events: none;
	}
}
